(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$http', '$timeout', '$localStorage', 'Auth', 'Principal', 'AlertService'];

    function LoginController ($rootScope, $state, $http, $timeout, $localStorage, Auth, Principal, AlertService) {
        var vm = this;
        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.requestResetPassword = requestResetPassword;
        vm.email = null;

        vm.isLoggingOut = Auth.isLoggingOut;

        Principal.identity().then(function(account) {
            if (account) $state.go('home');
        });

        $timeout(function (){angular.element('#email').focus();});


        function login (event) {
            event.preventDefault();
            Auth.login({
                email: vm.email,
                password: vm.password
            }).then(function (result) {
                $localStorage["auth-provider"] = "password";
                result.user.getIdToken(true)
                    .then(function (idToken) {
                        Auth.processLogin(idToken, vm)
                    })
            }).catch(function (err) {
                console.error(err);
                vm.authenticationError = true;
                if (err.code != null) {
                    AlertService.error("login.messages.error." + err.code.replace('auth/', ''), {});
                } else {
                    AlertService.error("login.messages.error.unknown", {});
                }
                $localStorage["auth-provider"] = null;
            });
        }

        function register () {
            firebase.auth().signOut().finally(function() {
                $state.go('register');
            });
        }

        function requestResetPassword () {
            $state.go('requestReset');
        }
    }
})();
