(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('valueconnectApp')
        .constant('VERSION', "0.0.1-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('SENTRY_ENABLED', true)
        .constant('GOOGLE_MAP_API_KEY', "AIzaSyDLYIWSSnbIfzLGT259PFbccfZMdImyOuc")
        .constant('GA4_MEASUREMENT_ID', "G-42QCVH6MEH")
        .constant('FIREBASE_CONFIG', {
	"apiKey": "AIzaSyCHXehqKa2C1S1cZV23nt15V1tCqytSFig",
	"authDomain": "pc-api-5136611492902132771-821.firebaseapp.com",
	"projectId": "pc-api-5136611492902132771-821",
	"storageBucket": "pc-api-5136611492902132771-821.appspot.com",
	"messagingSenderId": "55364655595",
	"appId": "1:55364655595:web:197bffca55ad7da4bee1ae"
})
;
})();
